import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSpinner.figmaUrl.android} codeUrl={checklists.componentSpinner.codeUrl.android} checklists={checklists.componentSpinner.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Spinner is a component to indicate a loading state or progress state of a page or section.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/spinner/android-1.png",
            "alt": "spinner banner"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h2>{`Usage`}</h2>
    <p>{`The following is code to implement spinner component.`}</p>
    <h3>{`Primary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`    LgnSpinner(
        variant = LgnSpinnerVariant.PRIMARY
    )
`}</code></pre>
    <h3>{`Secondary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`    LgnSpinner(
        variant = LgnSpinnerVariant.SECONDARY
    )
`}</code></pre>
    <h3>{`Error`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`    LgnSpinner(
        variant = LgnSpinnerVariant.ERROR
    )
`}</code></pre>
    <h4>{`White`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`    LgnSpinner(
        variant = LgnSpinnerVariant.WHITE
    )
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set size of Spinner by Height`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnSpinnerVariant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The variant of the spinner. Default value is `}<inlineCode parentName="td">{`LgnSpinnerVariant.PRIMARY`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set size of Spinner by Width`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      